const config = {
  production: false,
  development: false,

  functionHostKey: 'BWzmt/TwOiHoyoBlQtP7LmNUfbMnnExIiFnsUEHgjVi/5UuiPpXkKA==',
  publicFunctionHostKey:
    'cHJjdyoQ7r7qT7GgvndSy4Qfu15Dwfg7/ewHqkYJSuFDaCNmA5WSBg==',

  privateApiRoot: 'https://bahnkick-dev-private-api.azurewebsites.net/api',
  publicApiRoot: 'https://bahnkick-dev-api.azurewebsites.net/api',

  signalRHubUrl: 'https://bahnkick-dev-signalr.azurewebsites.net/api',

  LOG_ROCKET_KEY: '8kox0k/bahnkick-partner-dev',

  whatsnewUrl: 'https://partner.dev.bahnkick.com/whatsnew',
};

export default config;
